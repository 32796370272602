import PortfolioModal from "components/portfolio-modal";
import React from "react";
import PortfolioSquare from "../../../components/portfolio-square";
import {
  AT_IOS,
  AVNT_ANDROID,
  AVNT_IOS,
  MUSIC_ANDROID,
  MUSIC_IOS,
  OZUI_IOS,
  PITCH_IOS,
  RENEW_IOS,
  SPORTS_SQUARED_ANDROID,
  SPORTS_SQUARED_IOS,
} from "../../../constants/portfolio-text";

function Portfolio() {
  return (
    <div className="row no-gutters">
      <PortfolioSquare
        title="Sports Squared"
        description={`Sports Squared brings you a totally new way of winning prizes while watching your favorite sports games.`}
        target={"#sportsSquared"}
        cardImg={require("../../../img/ss/sportssquared-mockup.png")}
      />

      <PortfolioSquare
        title="AVNT"
        description={"The underground streetwear community."}
        target={"#avnt"}
        cardImg={require("../../../img/avnt/avntMobile2.png")}
      />

      <PortfolioSquare
        title="Pitch"
        description={`Camping on demand`}
        target={"#pitch"}
        cardImg={require("../../../img/pitch/pitch-mock-6.png")}
      />

      <PortfolioSquare
        title="The A.T. Guide"
        description={`A handbook for hiking the Appalachian Trail`}
        target={"#atguide"}
        cardImg={require("../../../img/atguide/at-mock-1.png")}
      />

      <PortfolioSquare
        title="MusicTown Live"
        description={`Purchase discounted tickets to last minute shows. The catch? Your show is a surprise.`}
        target={"#musictown"}
        cardImg={require("../../../img/musictown/musictown.png")}
      />

      <PortfolioSquare
        title="Spells"
        description={`An enchanting witchy-themed word making game for iOS.`}
        target={"#spells"}
        cardImg={require("../../../img/spells/spellsMain.png")}
      />

      <PortfolioSquare
        title="Scout"
        description={`A baseball app for scouts to search and track prospective players for their teams.`}
        target={"#scout"}
        cardImg={require("../../../img/scout/scout-mock-2.png")}
      />

      <PortfolioSquare
        title="Renew"
        description={`Renew is a mobile app designed for like-minded people over the age of 50 to connect with one another. `}
        target={"#renew"}
        cardImg={require("../../../img/renew/renew-4.png")}
      />

      <PortfolioSquare
        title="OZ-UI"
        description={`Control the custom-made IOT-enabled heat lamps for a lizard habitat`}
        target={"#ozui"}
        cardImg={require("../../../img/hardware/oz-ui.png")}
      />

      <PortfolioSquare
        title="Looperator"
        description={`Custom-build device with a 3D printed encasing to synchronize your looper pedals with a sequencer click track`}
        target={"#looper"}
        cardImg={require("../../../img/looper/looper_1.png")}
      />

      {/* Sports Squared Modal */}
      <div
        className="modal fade"
        id="sportsSquared"
        role="dialog"
        aria-labelledby="sportsSquared"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Sports Squared brings you a totally new way of winning prizes while watching your favorite sports games.`}
          backgroundColor={"#8A1B12"}
          title="Sports Squared"
          modalId="sportsCarousel"
          activeImg={require("../../../img/ss/sportssquared.png")}
          carouselImgs={[
            require("../../../img/ss/ss-mobile-2-mockup.png"),
            require("../../../img/ss/ss-mobile-1-mockup.png"),
          ]}
          links={[
            { name: "download for iOS", url: SPORTS_SQUARED_IOS },
            { name: "download for android", url: SPORTS_SQUARED_ANDROID },
          ]}
        />
      </div>

      {/* AVNT Modal */}
      <div
        className="modal fade"
        id="avnt"
        role="dialog"
        aria-labelledby="avnt"
        aria-hidden="true"
      >
        <PortfolioModal
          description={"The underground streetwear community."}
          backgroundColor={"#000"}
          title="AVNT"
          modalId="avntCarousel"
          activeImg={require("../../../img/avnt/avntMobile2.png")}
          carouselImgs={[
            require("../../../img/avnt/avnt-brands-mockup.png"),
            require("../../../img/avnt/avnt-feed-mockup.png"),
            require("../../../img/avnt/avnt-map-mockup.png"),
          ]}
          links={[
            { name: "download for iOS", url: AVNT_IOS },
            { name: "download for android", url: AVNT_ANDROID },
          ]}
        />
      </div>

      {/* Pitch Modal */}
      <div
        className="modal fade"
        id="pitch"
        role="dialog"
        aria-labelledby="pitch"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Camping on demand`}
          backgroundColor={"#fff"}
          textColor={"#478677"}
          title="Pitch"
          modalId="pitchCarousel"
          activeImg={require("../../../img/pitch/pitch-mock-5.png")}
          carouselImgs={[
            require("../../../img/pitch/pitch-mock-4.png"),
            require("../../../img/pitch/pitch-mock-7.png"),
          ]}
          links={[{ name: "view website", url: PITCH_IOS }]}
        />
      </div>

      {/* AT Guide Modal */}
      <div
        className="modal fade"
        id="atguide"
        role="dialog"
        aria-labelledby="at"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`A handbook for hiking the Appalachian Trail`}
          textColor={"#385B7C"}
          backgroundColor={"#fff"}
          title="The A.T. Guide"
          modalId="atguideCarousel"
          activeImg={require("../../../img/atguide/at-mock-2.png")}
          carouselImgs={[require("../../../img/atguide/at-mock-1.png")]}
          links={[{ name: "view website", url: AT_IOS }]}
        />
      </div>

      {/* MusicTown Modal */}
      <div
        className="modal fade"
        id="musictown"
        role="dialog"
        aria-labelledby="musictown"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Purchase discounted tickets to last minute shows. The catch? Your show is a surprise.`}
          backgroundColor={"#706DCF"}
          title="MusicTown Live"
          modalId="musicCarousel"
          activeImg={require("../../../img/musictown/musictownMobile.png")}
          carouselImgs={[
            require("../../../img/musictown/musictownMobile2.png"),
          ]}
          links={[
            { name: "download for iOS", url: MUSIC_IOS },
            { name: "download for android", url: MUSIC_ANDROID },
          ]}
        />
      </div>

      {/* Spells Modal */}
      <div
        className="modal fade"
        id="spells"
        role="dialog"
        aria-labelledby="spells"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`An enchanting witchy-themed word making game for iOS.`}
          backgroundColor={"#1B2950"}
          title="Spells"
          modalId="spellsCarousel"
          activeImg={require("../../../img/spells/spells-splash-mockup.png")}
          carouselImgs={[
            require("../../../img/spells/spells-gameplay-mockup.png"),
            require("../../../img/spells/spells-cast-mockup.png"),
          ]}
          links={[]}
        />
      </div>

      {/* Scout */}
      <div
        className="modal fade"
        id="scout"
        role="dialog"
        aria-labelledby="scout"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`A baseball app for scouts to search and track prospective players for their teams.`}
          backgroundColor={"#6D6D6D"}
          textColor={"#123262"}
          title="Scout"
          modalId="scoutCarousel"
          activeImg={require("../../../img/scout/scoutAdvancedSearch.png")}
          carouselImgs={[
            require("../../../img/scout/scoutPlayerProfile.png"),
            require("../../../img/scout/scoutNotification.png"),
          ]}
          links={[{ name: "coming soon", url: "#" }]}
        />
      </div>

      {/* Renew */}
      <div
        className="modal fade"
        id="renew"
        role="dialog"
        aria-labelledby="renew"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Renew is a mobile app designed for like-minded people over the age of 50 to connect with one another. `}
          backgroundColor={"#fff"}
          textColor={"#173EDC"}
          title="Renew"
          modalId="renewCarousel"
          activeImg={require("../../../img/renew/renewSplash.png")}
          carouselImgs={[
            require("../../../img/renew/renewFriends.png"),
            require("../../../img/renew/renewProfile.png"),
          ]}
          links={[{ name: "download for iOS", url: RENEW_IOS }]}
        />
      </div>

      {/* OZUI Modal */}
      <div
        className="modal fade"
        id="ozui"
        role="dialog"
        aria-labelledby="ozui"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Control the custom-made IOT-enabled heat lamps for a lizard habitat`}
          backgroundColor={"#32403F"}
          title="OZ-UI"
          modalId="ozuiCarousel"
          activeImg={require("../../../img/hardware/oz-ui.png")}
          carouselImgs={[require("../../../img/hardware/oz-ui.png")]}
          links={[{ name: "view website", url: OZUI_IOS }]}
        />
      </div>

      {/* OZUI Modal */}
      <div
        className="modal fade"
        id="looper"
        role="dialog"
        aria-labelledby="looper"
        aria-hidden="true"
      >
        <PortfolioModal
          description={`Synchronize your looper pedals with a sequencer click track`}
          backgroundColor={"#A66A6A"}
          title="Looperator"
          modalId="looperatorCarousel"
          activeImg={require("../../../img/looper/looper_1.png")}
          carouselImgs={[require("../../../img/looper/looper_1.png")]}
          links={[]}
        />
      </div>
    </div>
  );
}

export default Portfolio;
