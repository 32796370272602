import React from "react";
import "../../../setup/App.css";
import WhoWeAre from "./who-we-are";

function AboutUs() {
  return (
    <div style={{ maxWidth: 550, margin: "auto", color: "white" }}>
      <h3 className="text-secondary mb-0">our process</h3>
      <p className="mb-5" style={{ marginTop: 40 }}>
        We believe good communication is the key to a successful project. That's
        why we do weekly meetings and biweekly builds to keep you completely up
        to date on the progress of your project.
      </p>
      <p className="mb-5">
        We deliver completely custom apps with an emphasis on modern design and
        ux. In order to get the user experience just right, our projects include
        a round of beta testing for your early users and updates based on their
        feedback.
      </p>
      <p className="mb-5">
        When the app is ready, we'll walk you through the app store process and
        continue to make adjustments until your projects are approved.
      </p>
      {/* <h3 className="text-secondary mb-0">who we are</h3> */}
      {/* <WhoWeAre /> */}
    </div>
  );
}

export default AboutUs;
