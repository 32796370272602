import React from "react";
import "../../../setup/App.css";
import WhoWeAre from "./who-we-are";

function OurTeam() {
  const renderUser = (name, title, img) => {
    return (
      <div className="col-lg-3 col-md-6 mb-6 mb-lg-0">
        <img src={img} className="team-icon rounded-circle mx-auto mb-3" />

        <p className="text-secondary mb-0">
          <a style={{ color: "white" }} href="/design.html">
            {name}
          </a>
        </p>
        <p className="mb-5">{title}</p>
      </div>
    );
  };
  return (
    <div>
      {/* <h3 className="text-secondary mb-0">
        a small team of developers and designers
      </h3> */}
      <div className="row" style={{ marginTop: 40, marginBottom: 50 }}>
        {renderUser(
          "Tara Wilson",
          "Software Engineer",
          require("../../../img/tara.jpg")
        )}
        {renderUser(
          "Nick Tartaro",
          "Mobile Developer",
          require("../../../img/nick.jpg")
        )}
        {renderUser(
          "Brandon Wetzel",
          "Embedded Hardware Engineer",
          require("../../../img/brandon.jpg")
        )}
        {renderUser(
          "Jen Lewis",
          "Project Manager",
          require("../../../img/jean.png")
        )}
      </div>
      <div className="row" style={{ marginTop: 40 }}>
        {/* {renderUser(
          "Sathya Maybe",
          "Designer",
          require("../../../img/logo.png")
        )} */}
        {/* {renderUser("Someone Here", "Intern", require("../../../img/logo.png"))} */}
      </div>
    </div>
  );
}

export default OurTeam;
