import React from "react";
import "../setup/App.css";

function SectionThree(props: any) {
  return (
    <section
      // className="content-section content-section-gradient text-center"
      className="content-section text-white text-center"
      style={{ backgroundColor: "#a7abdd" }}
      id={props.idName}
    >
      <div className="container">{props.children}</div>
    </section>
  );
}
export default SectionThree;
