/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function PortfolioSquare(props: {
  title: string;
  description: string;
  target: string;
  cardImg: any;
}) {
  return (
    <div className="col-lg-6">
      <a
        className="portfolio-item"
        data-toggle="modal"
        data-target={props.target}
      >
        <span className="caption">
          <span className="caption-content">
            <h2
              style={{
                fontFamily: "Archivo Narrow",
                letterSpacing: 1.5,
                fontSize: "1.4rem",
              }}
            >
              {props.title}
            </h2>
            <p
              className="mb-0"
              style={{
                fontSize: "0.8rem",
                fontWeight: 700,
              }}
            >
              {props.description}
            </p>
          </span>
        </span>
        <img className="img-fluid" src={props.cardImg} alt="" />
      </a>
    </div>
  );
}
export default PortfolioSquare;
