// Sports Squared
export const SPORTS_SQUARED_IOS =
  "https://apps.apple.com/us/app/sports-squared/id1495595789";
export const SPORTS_SQUARED_ANDROID =
  "https://play.google.com/store/apps/details?id=com.tarawilson.squares";

// AVNT
export const AVNT_IOS =
  "https://apps.apple.com/us/app/avnt-streetwear-shopping/id1486420898?ls=1";
export const AVNT_ANDROID =
  "https://play.google.com/store/apps/details?id=avntspace.avntapp";

// MusicTown Live
export const MUSIC_IOS =
  "https://itunes.apple.com/us/app/musique-live/id1217586564";
export const MUSIC_ANDROID =
  "https://play.google.com/store/apps/details?id=com.musiqueliveapp";

// Renew
export const RENEW_IOS =
  "https://apps.apple.com/us/app/renew-social/id1531224537?itsct=apps_box&itscg=30200";

// Scout
export const SCOUT_IOS = "";
export const SCOUT_ANDROID = "";

// Pitch
export const PITCH_IOS = `https://www.pitchcampusa.com`;

// AT Guide
export const AT_IOS = "https://www.theatguide.com/";

// OZUI Guide
export const OZUI_IOS = "https://oz-ui.herokuapp.com/";
