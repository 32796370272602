import React, { Component, useEffect, useState } from "react";
import "../../setup/App.css";
import Splash from "../../components/splash";
import SectionHeader from "../../components/section-header";
import SectionOne from "../../sections/section-one";
import SectionTwo from "../../sections/section-two";
import SectionThree from "../../sections/section-three";
import AboutUs from "./sections/about-us";
import Portfolio from "./sections/portfolio";
import Services from "./sections/services";
import SectionFour from "sections/section-four";
import OurTeam from "./sections/our-team";

function Home() {
  return (
    <div>
      <Splash />

      <SectionOne idName={"services"}>
        <SectionHeader title={"Services"} />
        <Services />
      </SectionOne>

      <SectionTwo idName="portfolio">
        <div className="content-section-heading text-center">
          <SectionHeader title={"Portfolio"} />
          <p className="text-secondary" style={{ marginBottom: 50 }}>
            a selection of our recent work
          </p>
        </div>

        <Portfolio />
      </SectionTwo>

      <SectionThree idName="aboutus">
        <SectionHeader title={"Our Team"} />
        <OurTeam />
      </SectionThree>

      <SectionFour idName="ourTeam">
        <SectionHeader title={"How We Work"} />
        <AboutUs />
      </SectionFour>

      <SectionOne idName="pricing">
        <SectionHeader title={"Pricing"} />
        <div style={{ maxWidth: 600, margin: "auto", color: "white" }}>
          <h3 className="text-secondary mb-3">get in touch</h3>
          <p className="mb-5">
            Every app is different - that’s why we’ll work with you to come up
            with your app’s needs and specifications. After that, we can work
            together to land on a final fixed price cost. There are no hidden
            fees or hourly “estimates” that go over budget. Our pricing is
            completely transparent.
          </p>
          <p className="mb-5">
            Contact us today at team@marylandcodecollective.com to get started.
          </p>
        </div>
      </SectionOne>
      <a className="scroll-to-top rounded js-scroll-trigger" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
}

export default Home;
