import React from "react";
import "../setup/App.css";

function SectionFour(props: any) {
  return (
    <section
      className="content-section content-section-gradient text-center"
      id={props.idName}
    >
      <div className="container">{props.children}</div>
    </section>
  );
}
export default SectionFour;
