import React from "react";
import "../setup/App.css";
import SectionHeader from "../components/section-header";

function Footer() {
  return (
    <footer className="footer bg-primary text-center" id="contact">
      <div className="container">
        <SectionHeader title={"Contact"} />
        <p
          className="mb-5"
          style={{
            maxWidth: 550,
            margin: "auto",
            color: "white",
            marginBottom: 8,
          }}
        >
          team@marylandcodecollective.com
        </p>
        <p
          className="mb-5"
          style={{
            maxWidth: 550,
            margin: "auto",
            color: "white",
            marginBottom: 4,
          }}
        >
          (240) 381-8783
        </p>
        <h3 className="text-secondary mb-0" style={{ marginBottom: 20 }}>
          12301 Guinevere Rd,
          <br />
          Glenn Dale, MD 20769
        </h3>
        <br />

        {/* <ul className="list-inline mb-3 mt-1" style={{ marginTop: 36 }}>
          <li className="list-inline-item">
            <a className="social-link rounded-circle text-white mr-3" href="#">
              <i className="far fa-envelope"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a className="social-link rounded-circle text-white" href="#">
              <i className="fab fa-github"></i>
            </a>
          </li>
        </ul> */}
        <p className="text-muted small mb-1">
          Copyright &copy; Maryland Code Collective LLC. 2020
        </p>
      </div>
    </footer>
  );
}

export default Footer;
