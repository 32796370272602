import React from "react";
import "../../../setup/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPalette,
  faMobileAlt,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Services() {
  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
        <span className="service-icon rounded-circle mx-auto mb-3">
          <FontAwesomeIcon
            icon={faPalette}
            color={"white"}
            style={{
              height: 40,
              width: 40,
            }}
          />
        </span>

        <p className="text-secondary mb-0">
          <a style={{ color: "white" }} href="/design.html">
            design
          </a>
        </p>
        <p style={{ marginTop: 8 }}>
          We work with you to help design your app so that the final project
          matches your vision. We believe the small details make the difference
          between a good app and a great one.
        </p>
      </div>

      <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
        <span className="service-icon rounded-circle mx-auto mb-3">
          <FontAwesomeIcon
            icon={faMobileAlt}
            color={"white"}
            style={{
              height: 40,
              width: 40,
            }}
          />
        </span>

        <p className="text-secondary mb-0">
          <a style={{ color: "white" }} href="/development.html">
            development
          </a>
        </p>
        <p style={{ marginTop: 8 }}>
          We create native or hybrid apps for iOS and Android. We also implement
          the backend, along with any other web resources. Whatever you need,
          we'll make it happen.
        </p>
      </div>

      <div className="col-lg-4 col-md-6 mb-5 mb-md-0">
        <span className="service-icon rounded-circle mx-auto mb-3">
          <FontAwesomeIcon
            icon={faGlobe}
            color={"white"}
            style={{
              height: 40,
              width: 40,
            }}
          />
        </span>
        <p className="text-secondary mb-0">
          <a style={{ color: "white" }} href="/development.html">
            distribution
          </a>
        </p>

        <p style={{ marginTop: 8 }}>
          We'll walk you through distributing your app to beta users, iterating
          on their feedback and eventually deploying to the app store and google
          play store.
        </p>
      </div>
    </div>
  );
}

export default Services;
