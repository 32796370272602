import React from "react";
import "../setup/App.css";

function SectionTwo(props: any) {
  return (
    <section className="content-section bg-secondary" id={props.idName}>
      <div className="container">{props.children}</div>
    </section>
  );
}
export default SectionTwo;
