import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../setup/App.scss";

import Home from "../pages/home-page/home";
import Header from "../nav/header";
import Footer from "../nav/footer";

export default function App() {
  return (
    <Router>
      <div>
        <Header />

        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}
