import React from "react";
import "../setup/App.css";

function PortfolioModal(props: {
  title: string;
  activeImg: any;
  carouselImgs: any[];
  links: { name: string; url: string }[];
  backgroundColor: string;
  textColor?: string;
  modalId: string;
  description: string;
}) {
  return (
    <div className="modal-dialog" role="document">
      <div
        className="modal-content"
        style={{ backgroundColor: props.backgroundColor }}
      >
        <div className="modal-header">
          <span className="caption" style={{ paddingLeft: 10 }}>
            <span className="caption-content">
              <h2
                style={{
                  fontFamily: "Archivo Narrow",
                  letterSpacing: 1.5,
                  fontSize: "1.4rem",
                  color: props.textColor ? props.textColor : "#fff",
                }}
              >
                {props.title.toUpperCase()}
              </h2>
              <p
                className="mb-0"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 700,
                  color: props.textColor ? props.textColor : "#fff",
                }}
              >
                {props.description}
              </p>
            </span>
          </span>

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div id={props.modalId} className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block"
                  src={props.activeImg}
                  alt="First slide"
                  style={{ maxHeight: 500, margin: "auto", maxWidth: "80vw" }}
                />
              </div>
              {props.carouselImgs.map((item, index) => {
                return (
                  <div className="carousel-item">
                    <img
                      className="d-block"
                      src={item}
                      alt=""
                      style={{
                        maxHeight: 500,
                        margin: "auto",
                        maxWidth: "80vw",
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <a
              className="carousel-control-prev"
              href={`#${props.modalId}`}
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href={`#${props.modalId}`}
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            {(props.links || []).map((item, index) => {
              return (
                <a
                  style={{
                    color: props.textColor ? props.textColor : "#fff",
                    textDecoration: props.textColor
                      ? `${props.textColor} solid underline`
                      : "#fff solid underline",
                    marginLeft: 4,
                    marginRight: 12,

                    fontSize: "0.8rem",
                    fontWeight: 700,
                  }}
                  href={item.url}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PortfolioModal;
