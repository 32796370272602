import React, { Component, useEffect, useState } from "react";
import "../setup/App.css";
import Typed from "react-typed";

function Splash() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = () => {
    return width < 768;
  };

  if (isMobile()) {
    return (
      <header className={"masthead"}>
        <div style={{ height: "100vh" }}>
          <p
            style={{
              marginTop: 50,
              fontFamily: "Open Sans",
              fontSize: "2.3rem",
              fontWeight: 800,
              maxWidth: 700,

              padding: 0,
              textAlign: "center",
            }}
          >
            {"<maryland code collective/>"}
          </p>

          <div className="align-self-center " style={{ marginTop: 60 }}>
            <div className="row justify-content-start text-col">
              <h3 className="mb-5">
                BUILD AND LAUNCH YOUR NEW PRODUCT IN TWELVE WEEKS
              </h3>

              <p style={{ marginTop: 20 }}>
                Get a high quality, low cost MVP that's custom-made for your
                idea. We deliver a 100% personalized hand-coded solution,
                complete with app store approval and distribution. Our
                developers have contributed to more than 20 different apps that
                have appeared in the app store, some with hundreds of thousands
                of users.
              </p>
            </div>
          </div>
        </div>
        <a
          className="fas fa-chevron-down fa-3x js-scroll-trigger header-arrow"
          href="#services"
        ></a>
      </header>
    );
  }

  return (
    <header
      className={
        "masthead d-flex flex-column justify-content-between align-items-center"
      }
    >
      <div className="row" style={{ height: "100vh" }}>
        <div className="align-self-center title-wrapper">
          <div className="row justify-content-end title-col">
            <Typed
              style={{
                fontFamily: "Open Sans",
                fontSize: "4.5rem",
                fontWeight: 800,
                maxWidth: 700,
                margin: 0,
                padding: 0,
                textAlign: "right",
                paddingBottom: 30,
              }}
              strings={["&lt;maryland code collective /&gt;"]}
              typeSpeed={40}
            />
          </div>
        </div>

        <div className="col align-self-center left-border">
          <div className="row justify-content-start text-col">
            <h3 className="mb-5">
              BUILD AND LAUNCH YOUR NEW PRODUCT IN TWELVE WEEKS
            </h3>

            <p style={{ marginTop: 20 }}>
              Get a high quality, low cost MVP that's custom-made for your idea.
              We deliver a 100% personalized hand-coded solution, complete with
              app store approval and distribution. Our developers have
              contributed to more than 20 different apps that have appeared in
              the app store, some with hundreds of thousands of users.
            </p>
          </div>
        </div>
      </div>
      <a
        className="fas fa-chevron-down fa-3x js-scroll-trigger header-arrow"
        href="#services"
      ></a>
    </header>
  );
}
export default Splash;
