import React from "react";
import "../setup/App.css";

function SectionOne(props: any) {
  return (
    <section
      className="content-section bg-primary text-white text-center"
      id={props.idName}
    >
      <div className="container">{props.children}</div>
    </section>
  );
}
export default SectionOne;
