import React from "react";
import "../setup/App.css";

function Header() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "#a7abdd" }}
    >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarNavAltMarkup"
        style={{ justifyContent: "flex-end" }}
      >
        <div className="navbar-nav">
          <a className="js-scroll-trigger nav-item nav-link" href="#services">
            Services
          </a>
          <a className="js-scroll-trigger nav-item nav-link" href="#portfolio">
            Portfolio
          </a>
          <a className="js-scroll-trigger nav-item nav-link" href="#aboutus">
            About Us
          </a>
          <a className="js-scroll-trigger nav-item nav-link" href="#pricing">
            Pricing
          </a>
          <a className="js-scroll-trigger nav-item nav-link" href="#contact">
            Contact
          </a>

          {/* <a className="js-scroll-trigger nav-item nav-link" href="/blog.html">
            Blog
          </a> */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
